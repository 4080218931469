import { Menu } from 'antd';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const Sidebar: React.FC = () => {

  const navigate = useNavigate();

  const items = useMemo<MenuItemType[]>(() =>
    [
      {
        key: '1',
        label: 'Teams',
        onClick: () => navigate("/teamsList")
      },
      {
        key: '2',
        label: 'Monitors',
        onClick: () => navigate("/monitorsList")
      },
      {
        key: '3',
        label: 'Users',
        onClick: () => navigate("/usersList")
      }
    ]
  , [navigate])

  return (
    <>
      <Menu
        theme="light"
        defaultSelectedKeys={['1']}
        mode="inline"
        items={items} />
    </>
  );
};

export default Sidebar;